import React from 'react'

import { ErrorCodes } from '../../types/app'

interface ErrorBoundaryProps {
  errorCode: ErrorCodes
}

class ErrorBoundary extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps>
> {
  state = { hasError: false, errorCode: 0 }

  static getDerivedStateFromError() {
    return { hasError: true, errorCode: 500 }
  }

  componentDidMount(): void {
    if (this.props.errorCode !== 0) {
      this.setState({
        hasError: true,
        errorCode: this.props.errorCode,
      })
    }
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps): void {
    if (prevProps.errorCode !== this.props.errorCode) {
      this.setState({
        hasError: this.props.errorCode !== 0,
        errorCode: this.props.errorCode,
      })
    }
  }

  componentDidCatch(error: unknown) {
    console.error(error)
  }

  reset = () => {
    this.setState({ hasError: false, errorCode: 0 })
  }

  render() {
    if (this.state.hasError) {
      switch (this.state.errorCode) {
        case 404: {
          return (
            <div>
              Oh no! This survey doesn't exist or has expired. We are sorry for
              the inconvenience.
            </div>
          )
        }
        case 429: {
          return (
            <div>
              You have tried to load too many surveys recently. Please wait a
              minute before trying again.
              <button onClick={this.reset}>Try again</button>
            </div>
          )
        }
        case 500:
        default: {
          return (
            <div>
              Something went wrong while trying to load this survey. We have
              been notified of this issue and are looking into it. Sorry for the
              inconvenience.
              <button onClick={this.reset}>Try again</button>
            </div>
          )
        }
      }
    }

    return this.props.children
  }
}

export default ErrorBoundary
