const API_V2_ENDPOINT = 'https://apiv2.imbox.io/'
const API_V2_ENDPOINT_DEV = 'https://apiv2-dev.imbox.io/'

interface Constants {
  IS_PROD: boolean
  API_V2_ENDPOINT: string
}

export const getConstants = (): Constants => {
  const isProd = document.location.hostname !== 'survey-dev.imbox.io'

  return {
    IS_PROD: isProd,
    API_V2_ENDPOINT: isProd ? API_V2_ENDPOINT : API_V2_ENDPOINT_DEV,
  }
}
