import React from 'react'
import ReactDOM from 'react-dom/client'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import App from './components/app/app'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://238f63092b754a61b79e7ccdec354975@sentry.imbox.io/13',
    integrations: [new Integrations.BrowserTracing()],
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
