import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  background-color: #ebe9ed;
`

export const Logotype = styled.img`
  max-width: 200px;
  margin-top: 42px;
  max-height: 100px;
`

export const Card = styled.div`
  display: flex;

  background-color: white;
  width: 90%;
  max-width: 560px;
  border-radius: 8px;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);

  margin-top: 48px;
  margin-right: 32px;
  margin-bottom: 32px;
  margin-left: 32px;
  padding: 24px;
`

export const Branding = styled.a`
  font-family: 'Inter';
  text-decoration: none;
  font-size: 12px;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  transition: transform 100ms ease-in-out;
  color: #99979e;

  strong {
    font-family: 'Brezel Grotesk';
    color: #66656a;
  }

  &:hover {
    transform: scale(1.1);
  }
`

export const BrandingLogo = styled.img`
  height: 24px;
  transition: opacity 0.15s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`
