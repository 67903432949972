import React from 'react'
import { useParams } from 'react-router-dom'

import { Survey } from '../../types/app'
import { CheckContainer, ThankYouContainer } from './thank-you-styles'
import { CheckAnimation } from '@imbox/ui-components'

interface ThankYouPageProps {
  survey?: Survey
  loadSurvey: (externalId: string) => void
}

const ThankYouPage: React.FC<ThankYouPageProps> = ({ survey, loadSurvey }) => {
  const { externalId } = useParams()

  React.useEffect(() => {
    if (!externalId) throw new Error('ExternalId missing')
    if (!survey || survey.externalId !== externalId) loadSurvey(externalId)
  }, [survey, loadSurvey, externalId])

  if (!survey) return null

  const text =
    survey.viewSettings?.thankYouText ||
    'Thank you very much for your feedback!'

  return (
    <ThankYouContainer>
      <p>{text}</p>
      <CheckContainer>
        <CheckAnimation />
      </CheckContainer>
    </ThankYouContainer>
  )
}

export default ThankYouPage
