import React from 'react'
import { useNavigate } from 'react-router-dom'

import { getConstants } from '../../lib/constants'
import { Survey } from '../../types/app'
import { Spinner } from '../spinner/spinner-styles'
import { FormContainer } from './embedded-form-styles'

const { IS_PROD } = getConstants()

interface EmbeddedFormProps {
  survey: Survey
  onSubmitted: (externalId: string) => void
}

const EmbeddedForm: React.FC<EmbeddedFormProps> = ({ survey, onSubmitted }) => {
  const navigate = useNavigate()

  const [height, setHeight] = React.useState<string>('100%')
  const [loading, setLoading] = React.useState<boolean>(true)
  const [completed, setCompleted] = React.useState<boolean>(false)

  const iframeRef = React.useRef<HTMLIFrameElement | null>(null)

  const urlParams = React.useMemo(() => {
    const params = new URLSearchParams({
      formId: String(survey.formId),
      widgetId: String(survey.userId),
      ticketSurveyExternalId: survey.externalId,
    })

    if (!IS_PROD) {
      params.set('isDev', '1')
    }

    return params.toString()
  }, [survey.formId, survey.externalId, survey.userId])

  React.useEffect(() => {
    const messageHandler = (event: MessageEvent): void => {
      if (event.origin === 'https://detached-form.imbox.io')
        switch (event.data?.type) {
          case '@IMBOX/onSubmitChange':
            console.log(event.data.payload.event)

            if (event.data.payload.event === 'submitting') {
              setLoading(true)
              setCompleted(true)
              break
            }
            if (event.data.payload.event === 'submit-success') {
              onSubmitted(survey.externalId)
              navigate(`/thank-you/${survey.externalId}`)
              break
            }
            break
          case '@IMBOX/onPong':
            setLoading(false)
            break
          case '@IMBOX/onSizeChange':
            setHeight(event.data.payload.height + 'px')
            break
        }
    }

    window.addEventListener('message', messageHandler)

    return () => window.removeEventListener('message', messageHandler)
  }, [survey, survey.externalId, onSubmitted, navigate])

  React.useEffect(() => {
    let interval: number
    let attempt = 1
    if (!completed && loading) {
      interval = window.setInterval(() => {
        iframeRef.current?.contentWindow?.postMessage(
          {
            type: '@IMBOX/pingIframe',
            formId: survey.formId,
          },
          'https://detached-form.imbox.io'
        )
        attempt++
      }, 250 * attempt)
    }

    return () => clearInterval(interval)
  }, [loading, completed, survey.formId])

  if (!survey) throw new Error('Embedded Form missing survey')

  const iframeEnv = IS_PROD ? 'prod' : 'dev'
  const iframeSrc = `https://detached-form.imbox.io/${iframeEnv}/app/index.html?${urlParams}`

  return (
    <FormContainer className="form-container" style={{ height }}>
      {loading && <Spinner color="#000" />}
      <iframe
        title="Survey"
        id="imbox-form"
        ref={iframeRef}
        src={iframeSrc}
        height="100%"
        width="100%"
        data-init={true}
        data-dynamicheight="true"
        style={{ display: loading || completed ? 'none' : 'block' }}
        frameBorder={0}
      />
    </FormContainer>
  )
}

export default EmbeddedForm
