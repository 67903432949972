import styled from 'styled-components'

export const ThankYouContainer = styled.div`
  width: 100%;
  text-align: center;
  font-family: Inter;
  white-space: pre-line;
`

export const CheckContainer = styled.div`
  display: flex;
  justify-content: center;
`
