import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import EmbeddedForm from '../../components/embedded-form/embedded-form'
import { Survey } from '../../types/app'

interface SurveyPageProps {
  survey?: Survey
  loadSurvey: (externalId: string) => void
  markSurveyAsCompleted: (externalId: string) => void
}

const SurveyPage: React.FC<SurveyPageProps> = ({
  survey,
  loadSurvey,
  markSurveyAsCompleted,
}) => {
  const { externalId } = useParams()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!externalId) throw new Error('ExternalId missing')
    if (!survey || survey.externalId !== externalId) loadSurvey(externalId)
  }, [survey, loadSurvey, externalId])

  React.useEffect(() => {
    if (survey && !survey.isActive) navigate(`/thank-you/${externalId}`)
  }, [survey, externalId, navigate])

  if (!survey) return null

  return <EmbeddedForm survey={survey} onSubmitted={markSurveyAsCompleted} />
}

export default SurveyPage
