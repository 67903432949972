import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'

import { fetchSurvey } from '../../lib/fetch-survey'
import LandingPage from '../../pages/landing/landing-page'
import SurveyPage from '../../pages/survey/survey-page'
import ThankYouPage from '../../pages/thank-you/thank-you'
import { ErrorCodes, Survey } from '../../types/app'
import ErrorBoundary from '../error-boundry/error-boundry'
import { Card, Logotype, Main } from './app-styles'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    padding: 0;
    margin: 0;
    background-color: #000;
    font-family: 'Inter';
  }

  h1,h2 {
    font-family: 'Brezel Grotesk';
  }

  body {
    margin: 0;
    padding: 0;
  }

  #root {
    width: 100%;
    min-height: 100vh;
  }
`

const App: React.FC = () => {
  const [survey, setSurvey] = React.useState<Survey>()
  const [loadError, setLoadError] = React.useState<ErrorCodes>(0)

  React.useEffect(() => {
    const css = survey?.viewSettings.customCss
    const tag = document.createElement('style')
    if (css) {
      tag.innerHTML = css
      document.head.appendChild(tag)
    }

    return () => tag.remove()
  }, [survey, survey?.viewSettings.customCss])

  const loadSurvey = React.useCallback(
    (externalId: string) => {
      fetchSurvey(externalId)
        .then((res) => {
          if (!res) {
            setLoadError(404)
            return
          }

          setSurvey(res)
        })
        .catch((err) => {
          console.error(err.status)
          setLoadError(err.status)
        })
    },
    [setSurvey]
  )

  const markSurveyAsCompleted = React.useCallback(
    (externalId: string) => {
      if (survey?.externalId === externalId) {
        setSurvey({
          ...survey,
          isActive: false,
        })
      }
    },
    [survey]
  )

  return (
    <>
      <GlobalStyle />
      <Main className="main">
        <Logotype
          className="logotype"
          src={survey?.viewSettings?.companySettings?.logo || '/logo.png'}
        />
        <Card className="card">
          <ErrorBoundary errorCode={loadError}>
            <BrowserRouter>
              <Routes>
                <Route index element={<LandingPage />} />
                <Route
                  path="/survey/:externalId"
                  element={
                    <SurveyPage
                      survey={survey}
                      loadSurvey={loadSurvey}
                      markSurveyAsCompleted={markSurveyAsCompleted}
                    />
                  }
                />
                <Route
                  path="/thank-you/:externalId"
                  element={
                    <ThankYouPage survey={survey} loadSurvey={loadSurvey} />
                  }
                />
              </Routes>
            </BrowserRouter>
          </ErrorBoundary>
        </Card>
      </Main>
    </>
  )
}

export default App
