import { Survey } from '../types/app'
import { getConstants } from './constants'

interface GetSurveyResponse {
  survey: Survey
}

export const fetchSurvey = async (externalId: string): Promise<Survey> => {
  const { API_V2_ENDPOINT } = getConstants()

  const response = await fetch(
    `${API_V2_ENDPOINT}api/ticket-survey/${externalId}`,
    {
      method: 'GET',
    }
  )

  const json: GetSurveyResponse = await response.json()

  // Fix this later, why do I get view settings as json string? Prob bug in backend
  try {
    json.survey.viewSettings = JSON.parse(
      json.survey.viewSettings as unknown as string
    )
  } catch (err) {}

  return json.survey
}
