import React from 'react'

const LandingPage: React.FC = () => {
  return (
    <div>
      <h1>Welcome to ImBox Customer Surveys</h1>
      <p>
        Want to learn more about our services? Visit us at{' '}
        <a href="https://imbox.se/">imbox.se</a>
      </p>
    </div>
  )
}

export default LandingPage
