import styled, { keyframes } from 'styled-components'

const LoadAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

interface SpinnerProps {
  color: string
}

export const Spinner = styled.div<SpinnerProps>`
  border-radius: 50%;
  width: 8em;
  height: 8em;
  margin: 60px auto;
  font-size: 8px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(99, 74, 175, 0.2);
  border-right: 1.1em solid rgba(99, 74, 175, 0.2);
  border-bottom: 1.1em solid rgba(99, 74, 175, 0.2);
  border-left: ${(props) => '1.1em solid ' + props.color};
  transform: translateZ(0);
  animation: ${LoadAnimation} 1.1s infinite linear;

  &:after {
    border-radius: 50%;
    width: 8em;
    height: 8em;
  }
`
